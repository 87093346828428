import React, { useEffect, useState } from 'react';
import "./PreCheckout.css";
import { getSingleStockData } from '../../api/be-client';
import toast from 'react-hot-toast';
import { cryptoData, getCryptoPrices } from '../../api/crypto';
import DataChart from '../dataChart/DataChart';
import { Link, useNavigate } from 'react-router-dom';

const cryptoSymbols = [
    "BTC", "ETH", "BNB", "XRP", "ADA",
    "DOGE", "SOL", "TRX", "LTC", "DOT",
    "MATIC", "AVAX", "UNI", "LINK", "XMR",
    "ETC", "ATOM", "XLM", "ICP", "FIL",
    "APT", "HBAR", "VET", "NEAR", "QNT",
    "ALGO", "GRT", "EGLD", "STX", "EOS"
];

const formatMarketCap = (value) => {
    if (value >= 1e12) return `$${(value / 1e12).toFixed(2)}T`;
    if (value >= 1e9) return `$${(value / 1e9).toFixed(2)}B`;
    if (value >= 1e6) return `$${(value / 1e6).toFixed(2)}M`;
    return `$${value.toLocaleString()}`;
};

const PreCheckout = ({ selectedStock, onRequest, userLogged, handleCloseModal }) => {
    const [data, setData] = useState(null);
    const [showFullDescription, setShowFullDescription] = useState(false);

    const nav = useNavigate();
    useEffect(() => {
        const fetchData = async () => {
            if (cryptoSymbols.includes(selectedStock.symbol)) {
                try {
                    // Fetch crypto prices
                    const prices = await getCryptoPrices();

                    // Find the selected crypto from the prices list
                    const selectedCryptoPrice = prices.find(
                        (crypto) => crypto.symbol.toLowerCase() === selectedStock.symbol.toLowerCase()
                    );

                    if (selectedCryptoPrice) {
                        // Get the static data from cryptoData
                        const staticData = cryptoData[selectedStock.symbol.toUpperCase()];

                        // Combine the data
                        const combinedData = {
                            ...staticData,
                            market_cap: selectedCryptoPrice.market_cap,
                            // current_price: selectedCryptoPrice.current_price,
                            // price_change_percentage_24h: selectedCryptoPrice.price_change_percentage_24h,
                            // Add any other fields you need from the prices data
                            last_price: selectedCryptoPrice.current_price,
                            price_change_percent: selectedCryptoPrice.price_change_percentage_24h,
                        };

                        setData(combinedData);
                    } else {
                        toast.error("Crypto data not found");
                    }
                } catch (error) {
                    toast.error("Oops!! Error fetching crypto data. Please retry later or call support");
                }
            } else {
                // Fetch regular stock data
                try {
                    const stockData = await getSingleStockData(selectedStock.symbol);
                    setData(stockData);
                } catch (error) {
                    toast.error("Oops!! Error fetching stock data. Please retry later or call support");
                }
            }
        };

        fetchData();
    }, [selectedStock.symbol]);

    if (!data) return <p>Loading...</p>;

    // console.log(data);   

    return (
        <div className="pre-checkout-container">
            <h2 className="pre-checkout-title">{data.company_name}</h2>

            <a  target="_blank" rel="noopener noreferrer" className="pre-checkout-website">
                {selectedStock.symbol}
            </a>
            

            <div className="pre-checkout-info">

                <span><strong>Last Price:</strong> ${data.last_price.toFixed(2)}</span>
                <span className="pre-checkout-change">
                    24-Change: <span className={data.price_change_percent >= 0 ? "pre-checkout-positive" : "pre-checkout-negative"}>
                        {data.price_change_percent.toFixed(2)}%
                    </span>
                </span>
                {data.employees && <span><strong>Employees:</strong> {data.employees.toLocaleString()}</span>}
                {data.industry && <span><strong>Industry:</strong> {data.industry}</span>}
                {data.market_cap && <span><strong>Market Cap:</strong> {formatMarketCap(data.market_cap)}</span>}
            </div>




            {data.description && <p className="pre-checkout-description">
                {showFullDescription ? data.description : `${data.description.slice(0, 150)}...`}
                {!showFullDescription && (
                    <button className="pre-checkout-read-more" onClick={() => setShowFullDescription(true)}>
                        Read more
                    </button>
                )}
                {showFullDescription && (
                    <button className="pre-checkout-read-more" onClick={() => setShowFullDescription(false)}>
                        ...less
                    </button>
                )}
            </p>}


            <div className="pre-checkout-buttons">
                <button onClick={() => { onRequest("technical") }} className="pre-checkout-btn" disabled={!userLogged}>Get Technical Analysis</button>
                <button onClick={() => { onRequest("fundamental") }} className="pre-checkout-btn" disabled={!userLogged}>Get Fundamental Analysis</button>
                <button onClick={() => { onRequest("ai") }} className="pre-checkout-btn" disabled={!userLogged}>Get AI-Driven Analysis</button>
            </div>
            {!userLogged && <p className='pre-checkout-not-logged'> You must be logged in to request an analysis</p>}
            {!userLogged && <Link to="/login" onClick={()=>{handleCloseModal()}} className='pre-checkout-login'>Login</Link>}            
        </div>
    );
};

export default PreCheckout;