import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './SiteFooter.css';
import bosLogo from "../../assets/logo.png";
import social_1 from "../../assets/social_1.png";
import social_2 from "../../assets/social_2.png";
import toast from 'react-hot-toast';


const SiteFooter = () => {
  const nav = useNavigate();

  console.log("SiteFooter rendered..");

  return <div className='footer-container'>
    <div className='footer-up'>
      <div className='footer-logo-container'>
        <img className='footer-logo' onClick={() => {
          nav("/home");
        }} src={bosLogo} alt="" />
        <br />
        <p className='footer-log-text'>
          BUYORSELL connects you with expert analysts, <br />
          and cutting-edge AI tools, delivering reliable technical, <br />
          fundamental, or AI-driven insights to guide your <br />
          decisions in real time guide your decisions.
        </p>
      </div>
      <div className='footer-links'>
        <div className='footer-link-section'>
          <p className='footer-li-title'> Quick links </p>
          <p className='footer-link' onClick={() => { nav("/about") }}> About </p>
          <p className='footer-link' onClick={() => { nav("/how-it-works") }}> How it Works </p>
          <p className='footer-link' onClick={() => { nav("/FAQ") }}> FAQ </p>
          <p className='footer-link' onClick={() => { nav("/careers") }}> Careers </p>
        </div>
        <div className='footer-link-section'>
          <p className='footer-li-title'> Legal </p>
          <p className='footer-link' onClick={() => { nav("/terms-of-use") }}> Terms of Service </p>
          <p className='footer-link' onClick={() => { nav("/terms-of-use") }}> Privacy Policy </p>
          <p className='footer-link' onClick={() => { nav("/how-it-works") }}> How it Works </p>
          <p className='footer-link' onClick={() => { toast("Coming Soon..") }}> API Documentation </p>
        </div>
        <div className='footer-link-section'>
          <p className='footer-li-title'> Contact Us </p>
          <p className='footer-link' onClick={() => { nav("/contact-us") }}> Contact Form </p>
          <p className='footer-link'>
            Email: {" "}
            <span className='blue-text'>
              support@buyorsell.com </span>
          </p>
          <p className='footer-link'>
            WhatsApp: {" "}
            <span className='blue-text'>
              +972737864932
            </span>
          </p>
          <a className='footer-social-title'> Social </a>
          <div className='footer-social'>
            <img className='footer-social-link' src={social_2} alt="" onClick={() => window.open("https://www.instagram.com/buy_or_sell_stock", "_blank")} />
            <img className='footer-social-link' src={social_1} alt="" onClick={() => window.open("https://wa.me/972507776064", "_blank")} />
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div className='footer-bottom'>
      © 2024 BUYORSELL. All rights reserved.
    </div>
  </div>
};

export default React.memo(SiteFooter);
