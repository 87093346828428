import React, { useEffect, useState } from 'react';
import './HomeIndicator.css';
import { getMainStocksCharts } from '../../api/be-client';


const HomeIndicator = ({onStockClick}) => {
  const [stocksData, setStocksData] = useState({});

  useEffect(() => {
    getMainStocksCharts().then((res) => {
      setStocksData(res);
    });
  }, []);

  const renderChart = (prices) => {
    const chartWidth = 70;
    const chartHeight = 60;

    const maxPrice = Math.max(...prices);
    const minPrice = Math.min(...prices);

    const normalizePrice = (price) => {
      // Add 10% padding at top and bottom
      const buffer = (maxPrice - minPrice) * 0.4;
      return ((price - (minPrice - buffer)) / (maxPrice - minPrice + 2 * buffer)) * chartHeight;
    };

    const points = prices
      .map((price, index) => {
        const x = (index / (prices.length - 1)) * chartWidth;
        const y = chartHeight - normalizePrice(price);
        return `${x},${y}`;
      })
      .join(' ');

    return (
      <svg width={chartWidth} height={chartHeight}>
        <polyline points={points} fill="none" stroke="#28a745" strokeWidth="2" />
      </svg>
    );
  };

  return (
    <div className="home-indicators-container">
      {Object.values(stocksData).map((stock) => (
        <div className="home-indicator" key={stock.symbol}
        onClick={() => onStockClick(stock.symbol)}
        >

          <div className="left-section">
            <div className="symbol">{stock.symbol}</div>
            <div className="change" style={{ color: ((stock.data[stock.data.length - 1] - stock.data[0]) < 0 ? 'red' : '#28a745') }}>
              {(((stock.data[stock.data.length - 1] - stock.data[0]) / stock.data[0]) * 100).toFixed(2)}%
            </div>
          </div>
          <div className="right-section">
            {renderChart(stock.data, 60, 60)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default HomeIndicator;