import React, { useState, useEffect } from 'react';
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { sendRequest, isFirstAr } from '../../api/be-client';
// import './pp.css';
import './Checkout.css';

import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';



const reportTexts = {
    ai: {
        title: "AI-Powered Analysis Report",
        description: "Your AI-Powered Stock Analysis is Ready to Begin",
        intro: "Thank you for choosing BUYORSELL to enhance your investment decisions with cutting-edge AI technology!",
        details: [
            "AI-Generated Market Predictions – Machine-learning models forecasting price trends.",
            "Real-Time Data Insights – Analyzing market trends, news, and technical indicators.",
            "Risk & Volatility Assessment – AI-driven evaluation of price fluctuations and liquidity trends.",
            "Sentiment Analysis – Assessing news and social media impact on stock movements.",
            "Automated Buy/Sell Signals – AI-detected trading opportunities.",
            "Comparison to Traditional Analysis – AI vs. expert-driven technical & fundamental insights.",
            "Clear, Data-Driven Report – Presented in an easy-to-read format."
        ],
        price: "Complete your payment of $19.90 and receive your comprehensive fundamental analysis within 1 hour!",
        conclusion: "💡 Thank you for trusting BUYORSELL. We are committed to delivering cutting-edge insights powered by AI."
    },
    fundamental: {
        title: "Fundamental Analysis Report",
        description: "Your Fundamental Analysis is Ready to Begin",
        intro: "Thank you for choosing BUYORSELL for your fundamental stock analysis!",
        details: [
            "Company Financial Health – Key insights from income statements, balance sheets, and cash flow.",
            "Revenue & Profitability Trends – Analysis of earnings growth, profit margins, and financial sustainability.",
            "Valuation Metrics – P/E, P/B ratios, and industry benchmark comparisons.",
            "Debt & Risk Assessment – Review of debt levels, liabilities, and financial stability.",
            "Industry & Competitor Analysis – Company positioning vs. key competitors.",
            "Growth Potential & Outlook – Forecasts based on business performance and economic factors.",
            "Clear, Actionable Report – Well-structured insights for investors."
        ],
        price: "Complete your payment of $19.90 and receive your comprehensive fundamental analysis within 1 hour!",
        conclusion: "💡 We appreciate your trust and look forward to helping you make informed investment decisions."
    },
    technical: {
        title: "Technical Analysis Report",
        description: "Your Technical Analysis is Ready to Begin",
        intro: "Thank you for choosing BUYORSELL for your technical stock analysis!",
        details: [
            "Market Trends & Key Levels – Identify bullish/bearish trends, support & resistance levels, and market sentiment.",
            "Chart Patterns – Recognizing formations like head & shoulders, double tops/bottoms, and triangles.",
            "Candlestick Insights – Understanding price movements and reversal signals.",
            "Technical Indicators – Analysis of EMA, SMA, RSI, MACD, and Bollinger Bands.",
            "Entry & Exit Points – Suggested buy/sell zones based on price action.",
            "Clear, Actionable Report – Structured insights for easy decision-making."
        ],
        price: "Complete your payment of $19.90 and receive your comprehensive fundamental analysis within 1 hour!",
        conclusion: "💡 We appreciate your trust and look forward to providing valuable insights to guide your investment decisions."
    }
};



export const Checkout = ({ selectedStock, handleCloseModal, arType, setShowModal }) => {
    const [{ isPending }] = usePayPalScriptReducer();
    const [showIntro, setShowIntro] = useState(true);
    const [arText, setArText] = useState("");
    const [isFirstRequest, setIsFirstRequest] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const nav = useNavigate();

    useEffect(() => {
        const checkIfFirstRequest = async () => {
            try {
                const isFirst = await isFirstAr();
                // save on localStorage for the post-payment page:
                localStorage.setItem("isFirstRequest", isFirst);

                setIsFirstRequest(isFirst);
                setLoading(false);
            } catch (error) {
                console.error("Failed to check if first request:", error);
                setLoading(false);
            }
        };

        checkIfFirstRequest();
    }, []);

    const onCreateOrder = (data, actions) => {
        setShowIntro(false);
        setIsSubmitting(true);

        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: "0.1",
                    },
                },
            ],
            application_context: {
                shipping_preference: "NO_SHIPPING"
            }
        });
    };
    
    const onApproveOrder = (data, actions) => {
        setShowIntro(true);
        setIsSubmitting(true);
        return actions.order.capture().then(async (details) => {
            const name = details.payer.name.given_name;
            alert(`Transaction completed by ${name}`);
            const res = await sendRequest(selectedStock.symbol, arText);
            setIsSubmitting(false);
            if (res) {
                toast.success("Done");
                handleCloseModal();
                nav("/post-payment");
            } else {
                alert("Error 995. please retry later");
            }
        })
        .catch(error => {
            setIsSubmitting(false);
            console.error("Payment error:", error);
        });
    };
    
    const handleFreeRequest = async () => {
        setIsSubmitting(true);
        try {
            const res = await sendRequest(selectedStock.symbol, arText);
            setIsSubmitting(false);
            if (res) {
                toast.success("Your free analysis request has been sent!");
                handleCloseModal();
                setShowModal(false);
                nav("/post-payment");
            } else {
                alert("Error processing your request. Please try again later.");
            }
        } catch (error) {
            setIsSubmitting(false);
            console.error("Error sending free request:", error);
            alert("Error processing your request. Please try again later.");
        }
    };

    const { title, description, intro, details, price, conclusion } = reportTexts[arType];

    if (loading) {
        return <p className="loading-text">LOADING...</p>;
    }
    
    // Spinner component with inline styles
    const Spinner = () => (
        <div style={{
            display: 'inline-block',
            width: '20px',
            height: '20px',
            marginRight: '10px',
            border: '3px solid rgba(255, 255, 255, 0.3)',
            borderRadius: '50%',
            borderTopColor: '#ffffff',
            animation: 'spin 1s ease-in-out infinite'
        }}>
            <style>
                {`
                @keyframes spin {
                    to { transform: rotate(360deg); }
                }
                `}
            </style>
        </div>
    );
    
    return (
        <div className="checkout-container">
            <div className="checkout-paypal-container">
                {showIntro && <div>
                    {isFirstRequest && (
                        <p className="checkout-free-message">
                            Woohoo! Your first analysis is on us—completely FREE! Get ready for some amazing insights!
                        </p>
                    )}
                    <br />
                    <br />
                    <h3 className='checkout-title'> {title} For: {selectedStock.symbol}</h3>
                    <p> {description} </p>
                    <p> {intro} </p>
                    
                    
                    <p>
                        One of our expert analysts is preparing your detailed Analysis Report, sent to your email within 1 hour after payment.
                    </p>
                    <ul>
                        {details.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                    
                    {!isFirstRequest && <p> {price} </p>}
                    <p> {conclusion} </p>
                </div>}
                
                <h4 className='checkout-subtitle'> {selectedStock.symbol} - Personal Notes</h4>
                <div className='checkout-input-wrapper'>
                    <textarea
                        rows={4}
                        className='checkout-textarea'
                        type='text'
                        onChange={(e) => {
                            localStorage.setItem('arText', e.target.value);
                            setArText(e.target.value);
                        }}
                        placeholder='Specify unique personal analysis request (e.g., long-term investment)'
                    />
                </div>
                
                {isFirstRequest ? (
                    <button
                        className="checkout-free-btn"
                        onClick={handleFreeRequest}
                        disabled={isSubmitting}
                        style={{
                            position: 'relative',
                            cursor: isSubmitting ? 'not-allowed' : 'pointer',
                            opacity: isSubmitting ? '0.8' : '1'
                        }}
                    >
                        {isSubmitting ? (
                            <>
                                <Spinner />
                                Processing...
                            </>
                        ) : (
                            "Claim My Free Analysis Now!"
                        )}
                    </button>
                ) : (
                    <>
                        {isSubmitting && (
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBottom: '15px',
                                color: '#555'
                            }}>
                                <Spinner />
                                Processing your payment...
                            </div>
                        )}
                        <PayPalButtons
                            style={{ layout: "vertical" }}
                            createOrder={(data, actions) => onCreateOrder(data, actions)}
                            onApprove={(data, actions) => onApproveOrder(data, actions)}
                            disabled={isSubmitting}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

// import React, { useState } from 'react';
// import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
// import { sendRequest } from '../../api/be-client';
// import './pp.css';
// import { useNavigate } from 'react-router-dom';
// import toast from 'react-hot-toast';


// const reportTexts = {
//     ai: {
//         title: "AI-Powered Analysis Report",
//         description: "Your AI-Powered Stock Analysis is Ready to Begin",
//         intro: "Thank you for choosing BUYORSELL to enhance your investment decisions with cutting-edge AI technology!",
//         details: [
//             "AI-Generated Market Predictions – Machine-learning models forecasting price trends.",
//             "Real-Time Data Insights – Analyzing market trends, news, and technical indicators.",
//             "Risk & Volatility Assessment – AI-driven evaluation of price fluctuations and liquidity trends.",
//             "Sentiment Analysis – Assessing news and social media impact on stock movements.",
//             "Automated Buy/Sell Signals – AI-detected trading opportunities.",
//             "Comparison to Traditional Analysis – AI vs. expert-driven technical & fundamental insights.",
//             "Clear, Data-Driven Report – Presented in an easy-to-read format."
//         ],
//         price: "Complete your payment of $19.90 and receive your comprehensive fundamental analysis within 1 hour!",
//         conclusion: "💡 Thank you for trusting BUYORSELL. We are committed to delivering cutting-edge insights powered by AI."
//     },
//     fundamental: {
//         title: "Fundamental Analysis Report",
//         description: "Your Fundamental Analysis is Ready to Begin",
//         intro: "Thank you for choosing BUYORSELL for your fundamental stock analysis!",
//         details: [
//             "Company Financial Health – Key insights from income statements, balance sheets, and cash flow.",
//             "Revenue & Profitability Trends – Analysis of earnings growth, profit margins, and financial sustainability.",
//             "Valuation Metrics – P/E, P/B ratios, and industry benchmark comparisons.",
//             "Debt & Risk Assessment – Review of debt levels, liabilities, and financial stability.",
//             "Industry & Competitor Analysis – Company positioning vs. key competitors.",
//             "Growth Potential & Outlook – Forecasts based on business performance and economic factors.",
//             "Clear, Actionable Report – Well-structured insights for investors."
//         ],
//         price: "Complete your payment of $19.90 and receive your comprehensive fundamental analysis within 1 hour!",
//         conclusion: "💡 We appreciate your trust and look forward to helping you make informed investment decisions."
//     },
//     technical: {
//         title: "Technical Analysis Report",
//         description: "Your Technical Analysis is Ready to Begin",
//         intro: "Thank you for choosing BUYORSELL for your technical stock analysis!",
//         details: [
//             "Market Trends & Key Levels – Identify bullish/bearish trends, support & resistance levels, and market sentiment.",
//             "Chart Patterns – Recognizing formations like head & shoulders, double tops/bottoms, and triangles.",
//             "Candlestick Insights – Understanding price movements and reversal signals.",
//             "Technical Indicators – Analysis of EMA, SMA, RSI, MACD, and Bollinger Bands.",
//             "Entry & Exit Points – Suggested buy/sell zones based on price action.",
//             "Clear, Actionable Report – Structured insights for easy decision-making."
//         ],
//         price: "Complete your payment of $19.90 and receive your comprehensive fundamental analysis within 1 hour!",
//         conclusion: "💡 We appreciate your trust and look forward to providing valuable insights to guide your investment decisions."
//     }
// };


// export const Checkout = ({ selectedStock, handleCloseModal, arType }) => {
//     const [{ isPending }] = usePayPalScriptReducer();
//     const [showIntro, setShowIntro] = useState(true)
//     const [arText, setArText] = useState("")

//     const nav = useNavigate()
//     const onCreateOrder = (data, actions) => {
//         setShowIntro(false)


//         return actions.order.create({
//             purchase_units: [
//                 {
//                     amount: {
//                         value: "0.1",
//                     },
//                 },
//             ],
//             application_context: {
//                 shipping_preference: "NO_SHIPPING"
//             }
//         });
//     };
    
//     const onApproveOrder = (data, actions) => {
//         setShowIntro(true);
//         return actions.order.capture().then(async (details) => {
//             const name = details.payer.name.given_name;
//             alert(`Transaction completed by ${name}`);
//             const res = await sendRequest(selectedStock.symbol, arText);
//             if (res) {
//                 toast.success("Done");
//                 handleCloseModal();
//                 nav("/post-payment");
//             } else {
//                 alert("Error 995. please retry latter");
//             }
//         });
//     };

//     const { title, description, intro, details, price, conclusion } = reportTexts[arType];

//     return (
//         <div className="checkout">
//             {isPending ? <p className="loading-text">LOADING...</p> : (
//                 <div className="paypal-buttons-container">
//                     {showIntro && <div>
//                         <h3 className='co-prime-title'> {title} For:  {selectedStock.symbol}</h3>
//                         {/* <h5 className='co-second-title'> For:  { selectedStock.symbol}</h5> */}
//                         <p> {description} </p>
//                         <p> {intro} </p>
//                         <p>
//                             One of our expert analysts is preparing your detailed Analysis Report, which will be sent to your email within 1 hour after payment confirmation.
//                         </p>
//                         <ul>

//                             {details.map((item) => {
//                                 return <li>{item}</li>
//                             })}
//                         </ul>
//                         <p> {price} </p>
//                         <p> {conclusion} </p>

//                     </div>}
//                     <h4 className='co-second-title'> {selectedStock.symbol} - Personal Notes</h4>
//                     {/* input area to enter analysis request */}
//                     <div className='co-input-container'>
//                         <textarea rows={4} className='co-input' type='text' onChange={(e) => {
//                             localStorage.setItem('arText', e.target.value);
//                             setArText(e.target.value);
//                         }}
//                             placeholder='Specify unique personal analysis request (e.g., long-term investment)'
//                         />
//                     </div>
//                     <PayPalButtons
//                         style={{ layout: "vertical" }}
//                         createOrder={(data, actions) => onCreateOrder(data, actions)}
//                         onApprove={(data, actions) => onApproveOrder(data, actions)}
//                     />
//                 </div>
//             )}
//         </div>
//     );
// };
