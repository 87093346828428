import React, { useContext } from 'react';
import './About.css';

import aboutHiwPc from "../../assets/about-hiw-pc.PNG";
import aboutHiwMobile from "../../assets/about-hiw-mobile.PNG";
import about2Image from "../../assets/about-2-image.jpg";
import image3 from "../../assets/about3Image.jpg";
import { useNavigate } from 'react-router-dom';
import { appContext } from '../../App';


const About = () => {

  const nav = useNavigate();
  const {setShowSearchModal} = useContext(appContext)
  return (
    <div className="about-container">

      <div className="about-title-image-container">
        <h1 className='about-title'>About Us</h1>
      </div>

      <section className='about-1-container'>
        <div className='about-1-left'>
          <h2 className='about-1-title'>            
            <br />
            Your Real-Time Market Analysis Platform
          </h2>
          <p>
            BUYORSELL is the world's first platform that connects investors with professional market analysts and AI-driven insights—delivering expert analysis in just 60 minutes.
            <br />
            Financial markets move fast, and investment opportunities can disappear in seconds. But how can you be sure you're making the right decision?</p>
          <ul>
            <li>Our system intelligently matches your request with the most suitable analyst based on the type of analysis, market, and sector.</li>
            <li>Our advanced AI scans thousands of stocks in real-time, identifying hidden trends and providing you with a competitive edge.</li>
            <li>Our technical and fundamental analysts deliver precise, data-driven insights—ready for you in just 60 minutes.</li>
          </ul>
          <p>BUYORSELL was built to give you real financial insights—powered by human expertise and  cutting-edge AI, in real time.</p>
        </div>
        <div>
          <img className='about-1-img' src={image3} alt="" />
        </div>
      </section>

      <section className='about-wwo-container'>
        <h3 className='about-wwo-title'> What we offer? </h3>
        <p>Comprehensive Market Analysis – Technical, Fundamental, or AI-Powered</p>
        <h4>Looking for precise entry and exit points?</h4>
        <p>Our technical analysis provides clear insights into trend identification, support and  resistance levels, and price action patterns.</p>
        <h4>Want to assess a company's real value before investing?</h4>
        <p>Our fundamental analysis offers in-depth evaluation of financial statements, company  performance, and industry trends.</p>
        <h4>Interested in AI-driven insights like institutional investors?</h4>
        <p>Our AI system analyzes thousands of stocks in real time, uncovers hidden patterns, and  provides you with a data-driven market advantage.</p>
      </section>

      <section className='about-hiw only-pc'>
        <img className='about-hiw-pc' src={aboutHiwPc} alt="" />
      </section>

      <section className='about-hiw only-mobile'>
        <img className='about-hiw-pc' src={aboutHiwMobile} alt="" />
      </section>

      <section className='about-2-container'>

        <div className='only-pc'>
          <img className='about-2-img' src={about2Image} alt="" />
        </div>

        <div className='about-2-right'>
          <h2 className='about-2-title'>
            Why Choose BUYORSELL?
          </h2>
          <ul>
            <li>Expert market analysis delivered within 60 minutes—powered by top analysts and AI  technology.</li>
            <li>
              Direct access to market analysts from around the world—tailored to your specific  request.
            </li>
            <li>
              Clear, focused reports—no distractions, just the insights you need.
            </li>
          </ul>
          <h2 className='about-2-title'>
            Ideal for every type of investor
          </h2>
          <ul>
            <li>Need precise entry and exit points?</li>
            <li>Want to evaluate a company’s true value before investing?</li>
            <li>Looking for AI-driven insights to detect hidden market patterns?</li>
          </ul>
          <p>No matter what type of investor you are—we provide you with the tools to make smarter  investment decisions in real time.</p>
        </div>
      </section>


      <section className='about-wbb-container'>
        <h3>Who´s Behind BUYORSELL</h3>
        <p>
          BUYORSELL was created by traders—for traders. Behind the platform are leading technical and fundamental analysts, alongside AI  technology and data science experts.
          <br />
          <br className='mobile-only'/>
          We combine human expertise with advanced technology to provide investors with the  highest level of professional market analysis.
        </p>

      </section>


      <section className='about-footer-container'>
        <h3>Ready to Invest Smarter?</h3>
        <p>Get your expert report—delivered in just 60 minutes.</p>
        <button onClick={() => {
          setShowSearchModal(true);
          nav("/home");
        }}>Get Your Analysis Now</button>
      </section>



    </div>
  );
};

export default About;
