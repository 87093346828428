import React, { useContext } from 'react';
import "./Home.css";
import StocksTable from '../stocksTable/StocksTable';
import CryptoTable from '../CryptoTable/CryptoTable';
import { appContext } from '../../App';
import { stocksData } from '../../assets/stockData';
import toast from 'react-hot-toast';
import HomeIndicator from '../homeIndicator/HomeIndicator';
import HomeAdvantages from './homeAdvantages/HomeAdvantages';


const Home = () => {
    const { setSelectedStock, setShowPPmodal, setShowSearchModal } = useContext(appContext);
    const onStockClick = (symbol) => {
        const stock = Object.values(stocksData).find(stock =>
            stock.symbol.toLowerCase() === symbol.toLowerCase()
        );

        if (stock) {
            setSelectedStock(stock);
            setShowPPmodal(true);
        } else {
            toast.error(`Currently we are not providing analysis for ${symbol}`)
        }
    };
    console.log("Home Rendered");

    return (
        <div className='home-container'>

            <div className='gray-bg'>

                <HomeIndicator onStockClick={ onStockClick} />

                <h1 className='home-main-title'>
                    Stock & Crypto Analysis in 60 Minutes
                </h1>
                <h2 className='home-second-title'>
                    Your go-to platform for fast, accurate, and professional investment analysis –
                    <br className='only-pc' />
                    powered by technical, fundamental, and AI-driven insights.                    
                </h2>

                <div className="home-search-container">
                    <input
                        className="home-search-input"
                        onChange={() => setShowSearchModal(true)}
                        onClick={() => setShowSearchModal(true)}
                        placeholder="Search for a stock or crypto..."
                        value=""
                    />
                </div>
            </div>
            <div className='home-advantages-container'>
                <HomeAdvantages />
            </div>

            <div id='home-stocks-table-container'>
                <StocksTable headerString={"U.S. Stocks"} onStockClick={onStockClick} />
                <StocksTable headerString={"Trends Stocks"} onStockClick={onStockClick} />
            </div>
            <div id='home-crypto-table-container'>
                <CryptoTable onCryptoClick={onStockClick} />
            </div>
        </div>
    )
};

export default Home;