import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import SiteRoutes from "./SiteRoutes";
import { isTokenValid } from "./api/authApi";
import SiteHeader from "./components/siteHeader/SiteHeader";
import SiteFooter from "./components/siteFooter/SiteFooter";
import { useNavigate } from "react-router-dom";
import BlueTA from "./components/blueTA/BlueTA"
import { Toaster } from "react-hot-toast";
import SearchModal from "./components/searchModal/SearchModal";
import ChatBot from "./components/chatbot/ChatBot";
import PaypalArea from "./components/paypalArea/PaypalArea";

export const appContext = createContext(null);

function App() {
  const [userLogged, setUserLogged] = useState(false);
  const [userName, setUserName] = useState("");
  const [selectedStock, setSelectedStock] = useState({})
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [showPPmodal, setShowPPmodal] = useState(false)

  const memoizedSetShowSearchModal = useCallback(setShowSearchModal, []);
  const contextValue = useMemo(() => ({
    userLogged, setUserLogged,
    userName, setUserName,
    setShowSearchModal, setShowPPmodal,
    setSelectedStock
  }), [userLogged, userName]);

  const nav = useNavigate();

  // in-app browser detection
  useEffect(() => {
    const ua = navigator.userAgent.toLowerCase();
    const isInApp = /fb|instagram|line|twitter|linkedin|whatsapp|webview|fbios|instagram|crios.*facebook|fban|fbav|snapchat/i.test(ua);

    if (isInApp) {
      console.log("In App Browser");

      if (/iphone|ipad|ipod/i.test(ua)) {
        // Show alert for iOS devices
        // alert("For the best experience, open this page in Safari. Tap '...' → 'Open in Browser'.");
      } else if (/android/i.test(ua)) {
        // Redirect for Android devices
        try {
          openInDefaultBrowser('https://stocks-analysis.com');
        } catch (error) {
          console.error("Error opening browser:", error);
          // Fallback
          // nav("/login"); // seems wrong!
          // alert("For the best experience, open this page outside instagram. Tap '...' → 'Open in Chrome'.");          
        }
      }
    } else {
      console.log("Not in app");
    }
  }, []);

  const openInDefaultBrowser = (url) => {
    const ua = navigator.userAgent.toLowerCase();
  
    if (/android/i.test(ua)) {
      // Use intent for Android
      window.location.href = `intent://${url.replace(/^https?:\/\//, "")}#Intent;scheme=https;package=com.android.chrome;end;`;
    } else if (/iphone|ipad|ipod/i.test(ua)) {
      // Open in Safari for iOS
      const a = document.createElement("a");
      a.href = url;
      a.target = "_blank";
      a.rel = "noopener noreferrer";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      // Default fallback
      // window.open(url, "_blank");
    }
  };
  

  useEffect(() => {
    const token = localStorage.getItem("bos-token");
    if (token) {

      isTokenValid(token).then((res) => {
        if (res) {
          setUserLogged(true);
          setUserName(localStorage.getItem("bos-username"));

          if (window.location.pathname !== "/post-payment" && window.location.pathname !== "/home") {
            nav("/home");
          }

        } else {
          localStorage.removeItem("token");
        }
      })
    }
  }, []);

  return (
    <div>
      <Toaster position="top-center" />
      <div id="site-wrapper">
        <appContext.Provider
          value={contextValue}
        >

          <SiteHeader setShowSearchModal={memoizedSetShowSearchModal} />
          <div id="site-routes-wrapper">
            <SiteRoutes />
          </div>
        </appContext.Provider>
      </div>
      <SearchModal showSearchModal={showSearchModal} setShowSearchModal={setShowSearchModal} setSelectedStock={setSelectedStock} setShowPPmodal={setShowPPmodal} />
      <PaypalArea showModal={showPPmodal} setShowModal={setShowPPmodal}
        selectedStock={selectedStock}
        userLogged={userLogged} />
      <BlueTA userLogged={userLogged} />
      <div>
        <ChatBot userLogged={userLogged} />
        <SiteFooter />
      </div>
    </div>
  );
}

export default App;