import { SERVER_URL } from "./config";
import axios from "axios";


export async function isFirstAr() {
    try {
        const token = localStorage.getItem("bos-token");
        const url = SERVER_URL + "/is-first";
        const response = await axios.get(url, {
            headers: {
                Authorization: `Token ${token}`
            }
        });                
        
        return response.data.is_first || false;
    } catch (error) {
        console.error("Error checking if first AR:", error);
        return false;
    }
}


export async function sendRequest(symbol, arText) {

    arText = localStorage.getItem('arText');
    
    try {
        const token = localStorage.getItem("bos-token")
        const url = SERVER_URL + "/new-ar";
        const res = await axios.post(url, { symbol, arText }, {
            headers: {
                Authorization: `Token ${token}`
            },            
        });
        return res.status === 200;
    } catch (error) {
        console.error('Error sending AR:', error);
        return false;
    }
}
export async function sendContact(content, email) {
    try {
        const token = localStorage.getItem("bos-token")
        const url = SERVER_URL + "/contact-us";
        const res = await axios.post(url, { content, email }, {
            headers: {
                Authorization: `Token ${token}`
            }
        });
        return res.status === 200;
    } catch (error) {
        console.error('Error sending contact-us:', error);
        return false;
    }
}


export async function getStocksData() {
    const STOCKS_CACHE_KEY = 'bos-stocks-prices';
    const CACHE_DURATION = 15 * 60 * 1000; // 15 minutes
    const cachedData = localStorage.getItem(STOCKS_CACHE_KEY);
    if (cachedData) {
        const { timestamp, data } = JSON.parse(cachedData);
        if (new Date().getTime() - timestamp < CACHE_DURATION) {
            return data;
        }
    }

    const url = SERVER_URL + "/stocks-prices";
    try {
        const res = await fetch(url);
        const resJ = await res.json();
        localStorage.setItem(STOCKS_CACHE_KEY, JSON.stringify({ timestamp: new Date().getTime(), data: resJ }));                
        return resJ;
    } catch (error) {
        console.log(error);                
        alert("Error fetching data");
        return false;
    }
}

export async function getSingleStockData(symbol) {
    const STOCKS_CACHE_KEY = `bos-stock-data-${symbol}`;
    const CACHE_DURATION = 30 * 60 * 1000; // 30 minutes
    const cachedData = localStorage.getItem(STOCKS_CACHE_KEY);
    
    if (cachedData) {
        const { timestamp, data } = JSON.parse(cachedData);
        if (new Date().getTime() - timestamp < CACHE_DURATION) {
            return data;
        }
    }

    const url = SERVER_URL + "/single-stock-data/" + symbol;
    try {
        const res = await fetch(url);
        const resJ = await res.json();
        localStorage.setItem(STOCKS_CACHE_KEY, JSON.stringify({ timestamp: new Date().getTime(), data: resJ }));
        return resJ;
    } catch (error) {
        // alert("Error fetching data");
        return false;
    }
}

export async function getMainStocksCharts() {
    const CHARTS_CACHE_KEY = `bos-stocks-charts`;
    const CACHE_DURATION = 10 * 60 * 1000; // 10 minutes
    const cachedData = localStorage.getItem(CHARTS_CACHE_KEY);
    
    if (cachedData) {
        const { timestamp, data } = JSON.parse(cachedData);
        if (new Date().getTime() - timestamp < CACHE_DURATION) {
            return data;
        }
    }

    const url = SERVER_URL + "/stocks-chart";
    try {
        const res = await fetch(url);
        const resJ = await res.json();
        localStorage.setItem(CHARTS_CACHE_KEY, JSON.stringify({ timestamp: new Date().getTime(), data: resJ }));
        return resJ;
    } catch (error) {
        // alert("Error fetching data");
        return false;
    }
}
