import React from 'react';
import { useNavigate } from 'react-router-dom';
import './PostPayment.css';

const PostPayment = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/home');
  };

  const isFirst = localStorage.getItem('isFirstRequest');
  return (
    <div className="post-payment-container">
      {/* isFirstRequest */}

      {isFirst ?
        <h1> Analysis Request sent successfully </h1>
        :
        <h1>Thank you for your payment!</h1>}
      
      <p>You will receive an email shortly with your requested technical analysis.</p>
      <button onClick={handleRedirect} className="confirm-button">Back Home</button>
    </div>
  );
};

export default PostPayment;
