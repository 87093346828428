import React, { useState, useEffect } from 'react';
import { ArrowUpRight, ArrowDownRight } from 'lucide-react';
import { getStocksData } from '../../api/be-client';
import './StocksTable.css';
import AAPL_image from '../../assets/symbolsLogos/AAPL.png';
import ABNB_image from '../../assets/symbolsLogos/ABNB.png';
import AMD_image from '../../assets/symbolsLogos/AMD.png';
import AMZN_image from '../../assets/symbolsLogos/AMZN.png';
import BAC_image from '../../assets/symbolsLogos/BAC.jpeg';
import BRK_K_image from '../../assets/symbolsLogos/BRK-K.png';
import COIN_image from '../../assets/symbolsLogos/COIN.png';
import CVX_image from '../../assets/symbolsLogos/CVX.png';
import DIS_image from '../../assets/symbolsLogos/DIS.jpeg';
import GOOGL_image from '../../assets/symbolsLogos/GOOGL.jpeg';
import HOOD_image from '../../assets/symbolsLogos/HOOD.png';
import INTC_image from '../../assets/symbolsLogos/INTC.png';
import JPM_image from '../../assets/symbolsLogos/JPM.png';
import MA_image from '../../assets/symbolsLogos/MA.jpeg';
import META_image from '../../assets/symbolsLogos/META.png';
import MSFT_image from '../../assets/symbolsLogos/MSFT.jpeg';
import MSTR_image from '../../assets/symbolsLogos/MSTR.png';
import NFLX_image from '../../assets/symbolsLogos/NFLX.jpeg';
import NVDA_image from '../../assets/symbolsLogos/NVDA.png';
import ORCL_image from '../../assets/symbolsLogos/ORCL.png';
import PG_image from '../../assets/symbolsLogos/PG.png';
import PLTR_image from '../../assets/symbolsLogos/PLTR.png';
import PYPL_image from '../../assets/symbolsLogos/PYPL.png';
import RKLB_image from '../../assets/symbolsLogos/RKLB.png';
import SMCI_image from '../../assets/symbolsLogos/SMCI.png';
import SPOT_image from '../../assets/symbolsLogos/SPOT.jpeg';
import TSLA_image from '../../assets/symbolsLogos/TSLA.png';
import UBER_image from '../../assets/symbolsLogos/UBER.jpeg';
import UNH_image from '../../assets/symbolsLogos/UNH.jpeg';
import V_image from '../../assets/symbolsLogos/V.png';
import WMT_image from '../../assets/symbolsLogos/WMT.jpeg';
import XON_image from '../../assets/symbolsLogos/XON.jpeg';


const formatVolume = (volume) => {
  // Convert string with commas to number
  const num = parseInt(volume.replace(/,/g, ''));

  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1)}M`;
  }
  if (num >= 1000) {
    return `${(num / 1000).toFixed(1)}K`;
  }
  return num.toString();
};

const stockSymbol = {
  AAPL: AAPL_image,
  ABNB: ABNB_image,
  AMD: AMD_image,
  AMZN: AMZN_image,
  BAC: BAC_image,
  'BRK-K': BRK_K_image,
  COIN: COIN_image,
  CVX: CVX_image,
  DIS: DIS_image,
  GOOGL: GOOGL_image,
  HOOD: HOOD_image,
  INTC: INTC_image,
  JPM: JPM_image,
  MA: MA_image,
  META: META_image,
  MSFT: MSFT_image,
  MSTR: MSTR_image,
  NFLX: NFLX_image,
  NVDA: NVDA_image,
  ORCL: ORCL_image,
  PG: PG_image,
  PLTR: PLTR_image,
  PYPL: PYPL_image,
  RKLB: RKLB_image,
  SMCI: SMCI_image,
  SPOT: SPOT_image,
  TSLA: TSLA_image,
  UBER: UBER_image,
  UNH: UNH_image,
  V: V_image,
  WMT: WMT_image,
  XOM: XON_image,
};



const americanStocks = ['PG', 'CVX', 'UNH', 'MA', 'V', 'WMT', 'BAC', 'DIS', 'NFLX', 'AMZN', 'AAPL', 'MSFT', 'BRK-K', 'GOOGL', 'JPM', 'XOM'];

const trendingStocks = ['PLTR', 'NVDA', 'TSLA', 'AMD', 'COIN', 'UBER', 'HOOD', 'META', 'SMCI', 'INTC', 'PYPL', 'ORCL', 'MSTR', 'RKLB', 'ABNB', 'SPOT'];


const StocksTable = ({ onStockClick, headerString }) => {
  const [allStockData, setAllStockData] = useState([]);
  const [displayedStocks, setDisplayedStocks] = useState([]);
  
  
  const getRandomStocks = (stocks) => {

    const filteredStocks = stocks.filter(stock => {
      if (headerString.includes("U.S")) {
        return americanStocks.includes(stock.symbol);
      } else if (headerString === "Trends") {
        return trendingStocks.includes(stock.symbol) && !americanStocks.includes(stock.symbol); // Ensure no overlap
      }
      return true;
    });


    const shuffled = [...filteredStocks].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 8);
  };


  const fetchStockData = async () => {
    try {
      const response = await getStocksData();

      if (response && response.tickers) {

        const tickers = response.tickers.filter((t) => {
          // only save stocks that are in the headerString relevant array
          return headerString.includes("U.S") ? americanStocks.includes(t.ticker) : trendingStocks.includes(t.ticker);          
        })

        const formattedData = tickers.map(ticker => {
          const { day, prevDay } = ticker;

          // Check if current day data is valid
          const hasCurrentData = Object.values(day).some(value => value !== 0);

          const dataToUse = hasCurrentData ? day : prevDay;

          return {
            symbol: ticker.ticker,
            volume: dataToUse.v.toLocaleString(),
            price: dataToUse.c.toFixed(2),
            // change: hasCurrentData ? ticker.todaysChangePerc.toFixed(2) : null, // Consider handling no-change scenario            
            change: true ? ticker.todaysChangePerc.toFixed(2) : null, // Consider handling no-change scenario
          };
        });
        setAllStockData(formattedData);
      }
    } catch (error) {
      console.error('Error fetching stock data:', error);
    }
  };

  // Effect for initial data fetch
  useEffect(() => {
    fetchStockData();
  }, []);


  useEffect(() => {
    if (allStockData.length > 0) {
      setDisplayedStocks(getRandomStocks(allStockData));
      const rotateInterval = setInterval(() => {
        setDisplayedStocks(getRandomStocks(allStockData));
      }, 10000); // 10 sec
      // }, 10000); // 10 sec

      return () => clearInterval(rotateInterval);
    }
  }, [allStockData]);


  // console.log(`StocksTables ${headerString} rendered`);
  // console.log(`displayed stocks for ${headerString}: `, displayedStocks);

  return (
    <div className="stocks-table-container">
      <div className='top-stocks-header'>

        <span className='table-header-title'>
          {headerString}
        </span>
      </div>
      <table className="stocks-table">
        <thead>
          <tr>
            <th>Symbol</th>
            <th>Volume</th>
            <th>Price</th>
            <th id='th-dc'>Daily Change</th>
          </tr>
        </thead>
        <tbody>
          {displayedStocks.map((stock) => (
            <tr key={stock.symbol}
              onClick={
                () => { onStockClick(stock.symbol) }
              }>

              <td className='table-stocks-symbol'
               
              >
                <img className='symbol-image' src={stockSymbol[stock.symbol]} />
                <span className='symbol-name'> {stock.symbol} </span>
              </td>              
              <td>${formatVolume(stock.volume)}</td>
              <td className="stock-price">${stock.price}</td>            
              <td className={`stock-change ${stock.change >= 0 ? 'positive' : 'negative'}`}>
                {stock.change >= 0 ? (
                  <ArrowUpRight className="change-icon" size={16} />
                ) : (
                  <ArrowDownRight className="change-icon" size={16} />
                )}
                {stock.change}%
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StocksTable;
