import React, { useState, useEffect } from 'react';
import ChatHistoryWindow from './chatHistoryWindow/ChatHistoryWindow';
import ChatInputArea from './chatInputArea/ChatInputArea';
import './ChatBot.css';
import { MessageCircle, X } from 'lucide-react';
import { handleSend } from '../../api/chatbotApi';
import { useLocation, useNavigation } from 'react-router-dom';


const generateClientId = () => {
  return `${Date.now()}-${Math.floor(1000 + Math.random() * 9000)}`;
};


const ChatBot = ({ userLogged }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);

  const location = useLocation();
  console.log(location.pathname); // Current route path

  useEffect(() => {
    if (!localStorage.getItem('chatbot-client-id')) {
      localStorage.setItem('chatbot-client-id', generateClientId());
    }
  }, []);

  return (
    <div
    style={{        
        bottom: (userLogged || location.pathname.includes("login")) ? "50px" : "150px",
    }}
    className="chatbot-container"
    >
      {
        isOpen ? (
          <div className="chatbot-window"
          >
            <div className="chatbot-header">
              <span>Chat Assistant</span>
              <button onClick={() => setIsOpen(false)} className="close-button">
                <X size={20} />
              </button>
            </div>
            <ChatHistoryWindow messages={messages} />
            <ChatInputArea onSend={(msg) => handleSend(msg, messages, setMessages)} />
          </div>
        ) : (
          <div>

            {/* <span>Ask me anything</span> */}
            <button onClick={() => setIsOpen(true)} className="chatbot-trigger">
              <span className="ask-text">Ask us anything</span>
              <MessageCircle size={24} />
            </button>
          </div>
        )}
    </div >
  );
};

export default ChatBot;
