import React, { useContext } from "react";
import "./InAppLogin.css";
import { login, register } from "../../../api/authApi";
import { useNavigate } from "react-router-dom";
import { appContext } from "../../../App";
const InAppLogin = () => {
  const [isLogin, setIsLogin] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [rememberMe, setRememberMe] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);

  const { setUserName, setUserLogged } = useContext(appContext);
  const nav = useNavigate();

  const validateForm = () => {
    const newErrors = {};
    
    if (username.length < 4) {
      newErrors.username = "Username must be at least 4 characters";
    }
    
    if (password.length < 4) {
      newErrors.password = "Password must be at least 4 characters";
    }
    
    if (!isLogin) {
      // Email validation using regex
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        newErrors.email = "Please enter a valid email address";
      }
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleClick = async () => {
    if (!validateForm()) return;
    
    setIsLoading(true);
    let res;
    
    try {
      if (isLogin) {
        res = await login(username, password, email);
        console.log(res);
      } else {
        res = await register(username, password, email);
        console.log(res);      
      }
        
      if (res) {
        setUserName(res.username);  
        setUserLogged(true);
        nav("/home");
      }
    } catch (error) {
      console.error("Authentication error:", error);
      // Handle error here if needed
    } finally {
      setIsLoading(false);
    }
  };

  const toggleAuthMode = () => {
    setIsLogin(!isLogin);
    setErrors({});
  };

  return (
    <div className="in-app-login-container">
      <p className="in-app-login-intro"> To login using Google, open BuyOrSell with safari or chrome </p>
      <h2 className="in-app-login-title">{isLogin ? "Login" : "Signup Now"}</h2>
      {!isLogin && <p className="in-app-register-subtitle"> Get your first market analysis <br/> for FREE </p>}
      <input
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        className={`in-app-login-input ${errors.username ? "input-error" : ""}`}
        disabled={isLoading}
      />
      {errors.username && <p className="error-message">{errors.username}</p>}
      
      {!isLogin && (
        <>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={`in-app-login-input ${errors.email ? "input-error" : ""}`}
            disabled={isLoading}
          />
          {errors.email && <p className="error-message">{errors.email}</p>}
        </>
      )}
      
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className={`in-app-login-input ${errors.password ? "input-error" : ""}`}
        disabled={isLoading}
      />
      {errors.password && <p className="error-message">{errors.password}</p>}
      
      <div className="in-app-login-checkbox-container">
        <input
          type="checkbox"
          id="rememberMe"
          checked={rememberMe}
          onChange={(e) => setRememberMe(e.target.checked)}
          className="in-app-login-checkbox"
          disabled={isLoading}
        />
        <label htmlFor="rememberMe" className="in-app-login-checkbox-label">
          Remember me
        </label>
      </div>
      <button 
        onClick={handleClick} 
        className="in-app-login-button"
        disabled={isLoading}
      >
        {isLoading ? (
          <div className="spinner"></div>
        ) : (
          isLogin ? "Login" : "Register"
        )}
      </button>
      <button 
        onClick={toggleAuthMode} 
        className="in-app-login-toggle"
        disabled={isLoading}
      >
        {isLogin ? "Don't have an account? Sign up" : "Already have an account? Login"}
      </button>
    </div>
  );
};
  export default InAppLogin;