import { useGoogleLogin } from "@react-oauth/google";
import "./GoogleLoginButton.css";
import googleImage from "../../../assets/loginGoogle.png";

const GoogleLoginButton = ({ onSuccess, onFailure }) => {
  const login = useGoogleLogin({
    flow: "auth-code",
    onSuccess: (response) => {      
      console.log(response);      
      onSuccess(response.code);
    },
    onFailure: () => {      
      onFailure();
    }
  });

  return (
    <button
      onClick={() => login()}
      className="google-login-button"
    >
      <span className="google-login-button-text">
        Continue with Google
      </span>
      <img 
        className="google-login-button-icon"
        src={googleImage}
        alt="Google Logo"
      />
    </button>
  );
};

export default GoogleLoginButton;