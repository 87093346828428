import React from 'react'
import "./HomeAdvantages.css"

import image1 from "../../../assets/homeAdv/1.png";
import image2 from "../../../assets/homeAdv/2.png";
import image3 from "../../../assets/homeAdv/3.png";
import image4 from "../../../assets/homeAdv/4.png";
import image5 from "../../../assets/homeAdv/5.png";
import image6 from "../../../assets/homeAdv/6.png";

const SingleCube = ({image, text}) => {
    return <div className='home-adv-sc-container'>
        <img className='home-adv-sc-image' src={image} alt="" />
        <p className='home-adv-sc-text'> {text} </p>
    </div>
}


const HomeAdvantages = () => {
  return (
      <div className='home-adv-container'>
          <SingleCube image={image1} text="Free sign up"/>
          <SingleCube image={image2} text="Fundamental, technical and AI powered analyses"/>
          <SingleCube image={image3} text="Analysis in 60 minutes"/>
          <SingleCube image={image4} text="Stocks market and cryptocurrency"/>
          <SingleCube image={image5} text="Secured payment"/>
          <SingleCube image={image6} text="Professional and experienced analysts"/>
    </div>
  )
}

export default HomeAdvantages