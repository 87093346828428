import React, { useContext } from 'react'
import "./BlueTA.css"
import { useLocation, useNavigate } from 'react-router-dom'
import BlueGif from '../../assets/GIF_ARROW.svg';
import { appContext } from '../../App';
import ChatBot from '../chatbot/ChatBot';

const BlueTA = ({userLogged}) => {
  const loc = useLocation();    

  const nav = useNavigate();
  if (loc.pathname.includes("login") || userLogged) {
    return <>
    {/* <ChatBot/> */}
    </>
  }

  return (
    <div className='blue-ta-container' >
      <div className='bta-text-container'>
        <h3 className='bta-text1'>
          READY TO GET STARTED?
          <br />
        </h3>
        <h4 className='bta-text2'>
        Sign up now and get your first market analysis for FREE!
        </h4>
      </div>
      <div className='bta-arrows-container'>        
        <img src={BlueGif} alt="SVG" className='only-pc'/>
      </div>
      <button className='bta-btn' onClick={() => nav("/login")}> Create free account ➡</button>
      {/* <ChatBot/> */}
    </div>
  )
}

export default BlueTA