import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Checkout } from "./Checkout";
import { Modal } from "react-bootstrap";
import './pp.css';
import { useState } from "react";
import PreCheckout from "../preCheckout/PreCheckout";



const initialOptions = {
  "client-id": "AZXNCIOs88aDB_ODMRh7ZIwZ3AGZ6TuVBUQLtoFQV0QfUUm8FGiaboOuwgZM86UX_qCccXSKIeEvpQpO",
  currency: "USD",
  intent: "capture",
};

export default function PaypalArea({ showModal, setShowModal, selectedStock, userLogged }) {
  const [showCheckout, setShowCheckout] = useState(false);
  const [arType, setArType] = useState()  

  const handleOnRequest = (arType) => {
    setArType(arType)
    setShowCheckout(true);    
  }
  const handleCloseModal = () => {
    if (!showCheckout)
      setShowModal(false)
    else
      setShowCheckout(false)
  }
  return (
    <Modal size="lg" show={showModal} onHide={handleCloseModal} centered className="co-modal">
      <div className="pp-close-btn-container">
        <button className="pp-close-btn"
          onClick={handleCloseModal}>X</button></div>
      <div className="modal-content2">
        {showCheckout && <PayPalScriptProvider options={initialOptions}>
          <Checkout selectedStock={selectedStock} handleCloseModal={handleCloseModal} arType={arType} setShowModal={ setShowModal} />
        </PayPalScriptProvider>}

        {!showCheckout && (

          <PreCheckout selectedStock={selectedStock} onRequest={handleOnRequest} userLogged={userLogged} handleCloseModal={handleCloseModal} />          
        )}
      </div>
    </Modal>
  );
}
