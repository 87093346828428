import React, { useRef, useEffect } from 'react';
import './ChatHistoryWindow.css';

const ChatHistoryWindow = ({ messages }) => {
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className="chat-history">
      {messages.map((msg, index) => (
        <div key={index} className={`message ${msg.isResponse ? 'response' : 'user'}`}>
          <div className="message-content">{msg.content}</div>
          <div className="message-time">{new Date(msg.time).toLocaleTimeString()}</div>
        </div>
      ))}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default ChatHistoryWindow;
