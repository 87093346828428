import React, { useState, useEffect } from 'react';
import { getCryptoPrices } from '../../api/crypto';
import "./CryptoTable.css";

const CryptoTable = ({ onCryptoClick, headerString }) => {
    const [allCryptoData, setAllCryptoData] = useState([]);
    const [displayedCryptos, setDisplayedCryptos] = useState([]);


    // Function to get 8 random cryptos from the full dataset
    const getRandomCryptos = (cryptos) => {
        const shuffled = [...cryptos].sort(() => 0.5 - Math.random());
        return shuffled.slice(0, 8);
    };

    const fetchCryptoData = async () => {
        try {
            const response = await getCryptoPrices();
            if (response) {
                const formattedData = response.map(crypto => ({
                    symbol: crypto.symbol.toUpperCase(),
                    price: crypto.current_price.toFixed(3),
                    name: crypto.name,
                    image: crypto.image,
                    marketCap: crypto.market_cap.toLocaleString(),
                    volume: crypto.total_volume.toLocaleString(),
                    high: crypto.high_24h.toLocaleString(),
                    low: crypto.low_24h.toLocaleString(),
                    priceChange: crypto.price_change_24h.toFixed(4),
                    priceChangePercentage: crypto.price_change_percentage_24h.toFixed(1)
                }));
                setAllCryptoData(formattedData);
            }
        } catch (error) {
            console.error('Error fetching crypto data:', error);
        }
    };

    // Effect for initial data fetch
    useEffect(() => {
        fetchCryptoData();
    }, []);

    // Effect for setting up rotation and handling displayed cryptos
    useEffect(() => {
        if (allCryptoData.length > 0) {
            setDisplayedCryptos(getRandomCryptos(allCryptoData));
            const rotateInterval = setInterval(() => {
                setDisplayedCryptos(getRandomCryptos(allCryptoData));
            }, 10000); // 10 sec

            return () => clearInterval(rotateInterval);
        }
    }, [allCryptoData]);

    return (
        <div className="cryptos-table-container">
            <div className='top-stocks-header'>
                <span className='table-header-title'>
                    Crypto Prices
                </span>
            </div>

            <table className="cryptos-table">
                <thead>
                    <tr>
                        <th>Symbol</th>
                        <th>Price</th>
                        <th className='only-pc'>Market Cap</th>
                        <th className='only-pc'>Volume</th>
                        <th className='only-pc'>High</th>
                        <th className='only-pc'>Low</th>
                        <th className='only-pc'>24h Change</th>
                        <th>24h %</th>
                    </tr>
                </thead>
                <tbody>
                    {displayedCryptos.map((crypto) => (
                        <tr key={crypto.symbol}
                            onClick={() => onCryptoClick(crypto.symbol)}>
                            <td
                                className="cryptos-symbol"

                            >
                                <img
                                    className="cryptos-symbol-image"
                                    src={crypto.image}
                                    alt={crypto.name}
                                />
                                <span>{crypto.symbol}</span>
                            </td>
                            <td>${crypto.price}</td>
                            <td className='only-pc'>${crypto.marketCap}</td>
                            <td className='only-pc'>${crypto.volume}</td>
                            <td className='only-pc'>${crypto.high}</td>
                            <td className='only-pc'>${crypto.low}</td>
                            <td className={`only-pc cryptos-change ${parseFloat(crypto.priceChange) >= 0 ? 'positive' : 'negative'}`}>
                                ${Math.abs(crypto.priceChange)}
                            </td>
                            <td className={`cryptos-change ${parseFloat(crypto.priceChangePercentage) >= 0 ? 'positive' : 'negative'}`}>
                                {Math.abs(crypto.priceChangePercentage)}%
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default CryptoTable;