import axios from 'axios';
import { SERVER_URL } from './config';

export const handleSend = async (message, messages, setMessages) => {
  const newMessage = {
    content: message,
    time: new Date(),
    isResponse: false
  };
  
  setMessages(prev => [...prev, newMessage]);

  try {
    const headers = {};
    const token = localStorage.getItem('bos-token');
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    // const response = await axios.post(`${SERVER_URL}/ai/new`, {
    //   new_message: message,
    //   history: messages
    // }, { headers });
    const response = await axios.post(`${SERVER_URL}/ai/new`, {
      new_message: message,
      history: messages,
      client_id: localStorage.getItem('chatbot-client-id'),
      token: localStorage.getItem('bos-token')?.replace('Bearer ', '')
    }, { headers });

    const responseMessage = {
      content: response.data.response,
      time: new Date(),
      isResponse: true
    };

    setMessages(prev => [...prev, responseMessage]);
  } catch (error) {
    console.error('Error sending message:', error);
    // Optionally add error handling UI here
  }
};