import React from 'react';
import './HowItWorks.css';
import hiwImagePc from "../../assets/how-it-works-pc.png";
import hiwImageMobile from "../../assets/how-it-works-mobile.png";


const HowItWorks = () => {
  return (
    <div className='hiw-container'>
      <div className='hiw-1-container'>
        <h1 className='about-1-text'>HOW IT WORKS</h1>
      </div>



      <div className='hiw-2-container only-pc'>
        <h3 className='hiw-2-title'>4 simple steps to expert anaylsis
          <br />
          in 60 minutes
        </h3>
        <p className='hiw-2-text'>
          Receive fast, accurate insights tailored to your investment needs.
          <br />
          The process is simple, secure, and designed to help you make informed decisions
        </p>
      </div>


      <div className='hiw-2-container only-mobile'>
        <h3 className='hiw-2-title'>
          Fast, Simple, Smart – Get          
          Your Analysis in 5 Steps
        </h3>
        <p className='hiw-2-text'>
          Receive fast, accurate insights tailored to your investment needs.          
          The process is simple, secure, and designed to help you make informed decisions
        </p>
      </div>












      <div className='hiw-3-image-container'>
        <img className='hiw-3-pc only-pc' src={hiwImagePc} alt="" />
        <img className='hiw-3-mobile only-mobile' src={hiwImageMobile} alt="" />
      </div>

    </div>
  )
};

export default HowItWorks;
