import React, { useContext, useEffect, useState } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import { appContext } from "../../App";
import { GOOGLE_AUTH_CLIENT_ID, SERVER_URL } from "../../api/config";
import { getTokenByProviderCredential } from "../../api/authApi";

import image1 from "../../assets/login-1-1.png";
import image2 from "../../assets/login-1-2.png";
import image3 from "../../assets/login-1-3.png";
import GoogleLoginButton from "./googleLoginButton/GoogleLoginButton";
import InAppLogin from "./inAppLogin/InAppLogin";
import axios from "axios";



const clientId = GOOGLE_AUTH_CLIENT_ID;


const openInDefaultBrowser = (url) => {
  // Android Intent for Chrome
  const androidIntent = `intent://${url.replace('https://', '')}#Intent;scheme=https;package=com.android.chrome;end;`;
  // iOS fallback with standard link
  window.location.href = navigator.userAgent.match(/android/i) ? androidIntent : url;
};



const LoginInnerBox = ({ img, txt1, txt2 }) => {
  return <div className="inner-box-container">
    <img className='login-box-img' src={img} alt="" />
    <h5 className="login-box-title"> {txt1} </h5>
    <p className="login-box-text"> {txt2}</p>
  </div>
}

const Login = () => {
  const [isInApp, setIsInApp] = useState(false); // New state
  const nav = useNavigate();

  const { setUserLogged, setUserName, userLogged } = useContext(appContext);

  useEffect(() => {
    const notifyServer = async () => {

      try {
        await axios(SERVER_URL + "/login-page-entered")
      } catch (error) {

      }
    }
    notifyServer()
  }, [])

  useEffect(() => {
    const ua = navigator.userAgent.toLowerCase();
    const inApp = /fb|instagram|line|twitter|linkedin|whatsapp|webview|fbios|instagram|crios.*facebook|fban|fbav|snapchat/i.test(ua);
    setIsInApp(inApp);
  }, []);


  useEffect(() => {
    if (userLogged) {
      nav("/home");
      return; // Exit early if user is already logged in
    }
  }, [userLogged, nav]);

  const onSuccess = async (code) => {
    // console.log("Login Success at FE side:", response);
    const userName = await getTokenByProviderCredential(
      code,

      "google"
    );
    setUserName(userName);
    setUserLogged(true);
    nav("/home");
  };

  const onFailure = (response) => {
    console.log("Login Failed:", response);
  };


  if (isInApp) {
    return <>
      <InAppLogin />
    </>
  }

  return <div className="login-container">

    <div className="login-1-container">
      <div className="login-inner-container">

        <h2 className='login-title'>
          Stock  & Crypto Analysis in 60 Minutes
        </h2>
        <p className="login-1-text1">
          Your platform for fast, accurate, and professional analysis –<br className="pc-only" /> powered by technical, fundamental, and AI-driven insights.
        </p>
        <p className="login-1-text2">Get your first market analysis for FREE</p>
        <div className="login-boxes-container">
          <LoginInnerBox img={image1} txt1="Sign Up for Free" txt2='Get your first market analysis for FREE' />
          <LoginInnerBox img={image2} txt1="Professional, Real-Time Analysis" txt2='Get insights on stocks and crypto within 60 minutes' />
          <LoginInnerBox img={image3} txt1="Smart Insights with AI & Experts" txt2='Experts, Technical & Fundamental Analysis, Advanced AI' />

        </div>
        <br />
        <div className="lgoin-google-btn">
          <GoogleOAuthProvider clientId={clientId}>
            <GoogleLoginButton onSuccess={onSuccess} onFailure={onFailure} />
          </GoogleOAuthProvider>
        </div>
        <p className="login-inner-footer">
          Empower your investment decisions with expert-driven insights <br className="only-pc" /> and cutting-edge AI technology
        </p>
      </div>
    </div>
  </div>
};

export default Login;